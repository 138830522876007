import { Pen } from "lucide-react";
import { useState } from "react";

import { 
	useEditGlobalDayTypeMutation
} from "@/app/api/slices/dayTypeApiSlice";

import { VesselsSettingsDayTypesEditForm } from "@/components/forms/VesselsSettingsDayTypesEditForm";
import { type FormType } from "@/components/forms/VesselsSettingsDayTypesEditForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	dayTypeData: {
		id: string | number;
		name: string;
	}
};

export const VesselsSettingsDayTypesEditDialog = ({ dayTypeData }: Props) => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [editDayTypeMutation, { isLoading }] = useEditGlobalDayTypeMutation();

	const handleUpdateCrewMember = async (data: FormType) => {
		const requestBody = {
			name: data.name,
		}

		await editDayTypeMutation({ data: requestBody, dayId: dayTypeData.id })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully updated day type.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button
					className="edit-crew-member-button"
					variant="outline"
					size="icon"
					style={{ background: "#2055A3" }}
				>
					<Pen color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Edit Day Type</DialogTitle>
				</DialogHeader>

				<VesselsSettingsDayTypesEditForm
					onSubmit={handleUpdateCrewMember}
					isLoading={isLoading}
					defaultValues={{
						name: dayTypeData.name,
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};
