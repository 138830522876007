import { useState } from "react";
import { Outlet } from "react-router-dom";

import { selectAuthStatus } from "@/app/slices/authSlice";
import { FullscreenLoader } from "@/components/FullscreenLoader";
import { SideMenu } from "@/components/SideMenu";
import { TerminalTopBar, TopBar } from "@/components/TopBar";
import { useNotificationBar } from "@/contexts/NotificationBarContext";
import { useAuth } from "@/hooks/useAuth";
import { useSelector } from "react-redux";

export const AppLayout = () => {
	const authStatus = useSelector(selectAuthStatus);
	const auth = useAuth();
	const isTerminal = auth?.user?.role.name === "terminal";
	const { BarNotifications } = useNotificationBar();
	const [isExpanded, setIsExpanded] = useState(false);

	if (authStatus === "idle" || authStatus === "loading") {
		return <FullscreenLoader />;
	}

	const toggleExpanded = () => {
		setIsExpanded((isExpanded) => !isExpanded);
	};

	return (
		<div className="flex h-full">
			{!isTerminal && <SideMenu isExpanded={isExpanded} onExpandedChange={toggleExpanded} />}
			<div
				className={`flex max-w-full flex-auto flex-col transition-all duration-300 ${isTerminal ? "max-w-full" : "sm:max-w-[calc(100%-64px)] lg:border-l"}`}
			>
				{BarNotifications}
				{isTerminal ? <TerminalTopBar /> : <TopBar onExpandedChange={toggleExpanded} />}

				<div className="relative grow p-4">
					<Outlet />
				</div>
			</div>
		</div>
	);
};
