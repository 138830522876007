import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { selectAuthStatus } from "@/app/slices/authSlice";
import { FullscreenLoader } from "@/components/FullscreenLoader";
import { useAuth } from "@/hooks/useAuth";

export function PublicPagesOutlet() {
	const auth = useAuth();
	const authStatus = useSelector(selectAuthStatus);

	if (authStatus === "loading") {
		return <FullscreenLoader />;
	}

	const user = auth?.user;

	if (!user) {
		return <Outlet />;
	}

	const isManager = ["manager", "fleet_manager", "hod"].includes(user?.role.name);
	const isTerminal = user?.role.name === "terminal";

	if (isTerminal) {
		return <Navigate to="/crew-members" />;
	}

	return isManager ? <Navigate to="/" /> : <Navigate to="/vessels" />;
}
