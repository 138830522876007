import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { Trash2 } from "lucide-react";
import { useState } from "react";

import { 
	useDeleteGlobalDayTypeMutation
} from "@/app/api/slices/dayTypeApiSlice";

type Props = { dayId: number | string; disabled?: boolean };

export const VesselsSettingsDayTypesDeleteDialog = ({ dayId, disabled }: Props) => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [removeCrewMemberMutation, { isLoading }] = useDeleteGlobalDayTypeMutation();

	const handleRemoveDayType = async () => {
		await removeCrewMemberMutation({ dayId })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully removed day type.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button
					className="crew-member-remove-button"
					variant="outline"
					size="icon"
					style={{ background: "#F44336" }}
					disabled={disabled}
				>
					<Trash2 color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="max-h-full sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Are you absolutely sure?</DialogTitle>
					<DialogDescription>
						This action cannot be undone. This will permanently delete day type.
					</DialogDescription>
				</DialogHeader>
				<div className="flex justify-between">
					<DialogClose asChild>
						<Button type="button" variant="secondary">
							Cancel
						</Button>
					</DialogClose>
					<Button onClick={handleRemoveDayType} disabled={isLoading}>
						<ButtonLoader isLoading={isLoading} />
						Continue
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};
