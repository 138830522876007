import * as Sentry from "@sentry/react";
// import * as React from "react";
// import {
// 	createRoutesFromChildren,
// 	matchRoutes,
// 	useLocation,
// 	useNavigationType,
// } from "react-router-dom";

export function initSentry() {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_IS_TEST ? "development" : "production",
		tracePropagationTargets: ["*"],
		integrations: [
			// Sentry.reactRouterV6BrowserTracingIntegration({
			// 	useEffect: React.useEffect,
			// 	useLocation,
			// 	useNavigationType,
			// 	createRoutesFromChildren,
			// 	matchRoutes,
			// }),
			Sentry.browserTracingIntegration({}),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: import.meta.env.VITE_IS_TEST ? 1 : 0.3,
		// tracePropagationTargets: [
		// 	"localhost",
		// 	"https://app.myrotat.com",
		// 	"https://app.myrotat.com/",
		// 	"https://rotat.stagetest.it",
		// 	"https://rotat.stagetest.it/",
		// ],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		enabled: import.meta.env.PROD,
		debug: import.meta.env.VITE_IS_TEST ? true : false,
	});
}
