export function parseDateLocal(dateString: string | Date): Date {
    if(!dateString) {
        // throw new Error("Argument must by of type string");
        return new Date();
    }

    if(typeof dateString === 'string') {
        if (dateString.includes("T") || dateString.includes("Z") || dateString.includes(" ")) {
            return new Date(dateString);
        }

        const normalizedDate = dateString.replace(/-/g, "/");
        const [year, month, day] = normalizedDate.split('/').map(Number);
    
        // This prevents the date from being parsed as UTC (omits the timezone offset)
        return new Date(year, month - 1, day);
    } else {
        return dateString;
    }
}
