import { type Table } from "@tanstack/react-table";

import { useGetDepartmentsAndPositionsQuery } from "@/app/api/slices/admin/departmentsAndPositionsApiSlice";
import { AddCrewMemberDialog } from "@/components/dialogs/AddCrewMemberDialog";
import { ImportCrewMemberDialog } from "@/components/dialogs/ImportCrewMemberDialog";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { useAuth } from "@/hooks/useAuth";
import { useURLFilters } from "@/hooks/useCrewMembersURLFilters";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { type User } from "@/types/User";
import { useEffect } from "react";

type Props = {
	table?: Table<User>;
} & (
	| { data: User[]; setVisibleData: (data: User[]) => void }
	| { data?: never; setVisibleData?: never }
);

export const CrewMembersDataControls = ({ table }: Props) => {
	const auth = useAuth();
	const isTerminal = auth?.user?.role.name === "terminal";
	const vesselId = useCurrentVesselId();

	const { data: departmentsResponse, isLoading } = useGetDepartmentsAndPositionsQuery({ vesselId });

	return (
		<div className="mb-4 flex flex-wrap items-start justify-between gap-x-4 gap-y-2">
			{!isTerminal && (
				<>
					<AddCrewMemberDialog />
					<ImportCrewMemberDialog />
				</>
			)}
			<div className="flex shrink grow flex-wrap-reverse justify-start gap-2 sm:justify-end">
				<div
					id="crew-member-listing-filters"
					className="flex flex-wrap justify-start gap-2 sm:justify-end lg:flex-nowrap"
				>
					{table ? (
						<>
							<TableDepartmentsFilter
								isLoading={isLoading}
								departments={departmentsResponse?.data || []}
								table={table}
							/>
							<TableSignedOnOffFilter table={table} />
							<TableOnBoardFilter table={table} />
							<TableSearch table={table} />
						</>
					) : (
						<GridFilters isLoading={isLoading} departments={departmentsResponse?.data || []} />
					)}
				</div>
			</div>
		</div>
	);
};

const TableDepartmentsFilter = ({
	isLoading,
	departments,
	table,
}: {
	isLoading: boolean;
	departments: { id: number; name: string }[];
	table: Table<User>;
}) => {
	if (isLoading) return <Skeleton className="h-10 w-60" />;

	// Get the current filter value for "department" (defaulting to "")
	const departmentFilter =
		table.getState().columnFilters.find((f) => f.id === "department")?.value || "";

	return (
		<Select
			value={departmentFilter as string}
			onValueChange={(e) => {
				if (e === "all") return table.getColumn("department")?.setFilterValue(undefined);
				return table.getColumn("department")?.setFilterValue(e);
			}}
		>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Department filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					{departments.map((department) => {
						return (
							<SelectItem value={department.name} key={department.name}>
								{department.name}
							</SelectItem>
						);
					})}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
const TableSignedOnOffFilter = ({ table }: { table: Table<User> }) => {
	// Get the current filter value for "isSignedOn" and convert it to a string
	const filter = table.getState().columnFilters.find((f) => f.id === "isSignedOn")?.value;
	let filterValue = "";
	if (filter === true) filterValue = "yes";
	else if (filter === false) filterValue = "no";

	return (
		<Select
			value={filterValue}
			onValueChange={(e) => {
				if (e === "all") return table.getColumn("isSignedOn")?.setFilterValue(undefined);
				return table.getColumn("isSignedOn")?.setFilterValue(e === "yes" ? true : false);
			}}
		>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Signed on/off filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					<SelectItem value="yes">Signed on</SelectItem>
					<SelectItem value="no">Signed off</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
const TableOnBoardFilter = ({ table }: { table: Table<User> }) => {
	// Get the current filter value for "isOnboard" and convert it to a string
	const filter = table.getState().columnFilters.find((f) => f.id === "isOnboard")?.value;
	let filterValue = "";
	if (filter === true) filterValue = "yes";
	else if (filter === false) filterValue = "no";

	return (
		<Select
			value={filterValue}
			onValueChange={(e) => {
				if (e === "all") return table.getColumn("isOnboard")?.setFilterValue(undefined);
				return table.getColumn("isOnboard")?.setFilterValue(e === "yes" ? true : false);
			}}
		>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Onboard filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					<SelectItem value="yes">Yes</SelectItem>
					<SelectItem value="no">No</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
const TableSearch = ({ table }: { table: Table<User> }) => {
	return (
		<Input
			placeholder="Search..."
			value={table.getState().globalFilter ?? ""}
			onChange={(event) => table.setGlobalFilter(event.target.value)}
			className="max-w-sm"
		/>
	);
};

const GridFilters = ({
	isLoading,
	departments,
}: {
	isLoading: boolean;
	departments: { id: number; name: string }[];
}) => {
	const auth = useAuth();
	const isTerminal = auth?.user?.role.name === "terminal";

	const {
		globalFilter,
		departmentFilter,
		signedOnFilter,
		onBoardFilter,
		setGlobalFilter,
		setColumnFilter,
	} = useURLFilters();

	useEffect(() => {
		if (isTerminal) {
			setColumnFilter("isSignedOn", true);
		}
	}, [isTerminal, signedOnFilter]);

	return (
		<>
			<GridDepartmentsFilter
				value={departmentFilter === "all" ? "" : departmentFilter}
				onValueChange={(value) => setColumnFilter("department", value)}
				isLoading={isLoading}
				departments={departments}
			/>
			<div className={isTerminal ? "hidden" : ""}>
				<GridSignedOnOffFilter
					value={signedOnFilter === "all" ? "" : signedOnFilter}
					onValueChange={(value) =>
						setColumnFilter("isSignedOn", value === "all" ? undefined : value === "yes")
					}
				/>
			</div>
			<GridOnBoardFilter
				value={onBoardFilter === "all" ? "" : onBoardFilter}
				onValueChange={(value) =>
					setColumnFilter("isOnboard", value === "all" ? undefined : value === "yes")
				}
			/>
			<GridSearch value={globalFilter} onValueChange={setGlobalFilter} />
		</>
	);
};
const GridDepartmentsFilter = ({
	value,
	onValueChange,
	isLoading,
	departments,
}: {
	value: string | undefined;
	onValueChange: (value: string) => void;
	isLoading: boolean;
	departments: { id: number; name: string }[];
}) => {
	if (isLoading) return <Skeleton className="h-10 w-60" />;

	return (
		<Select value={value} onValueChange={onValueChange}>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Department filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					{departments.map((department) => {
						return (
							<SelectItem value={department.name} key={department.name}>
								{department.name}
							</SelectItem>
						);
					})}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
const GridSignedOnOffFilter = ({
	value,
	onValueChange,
}: {
	value: string | undefined;
	onValueChange: (value: string) => void;
}) => {
	return (
		<Select value={value} onValueChange={onValueChange}>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Signed on/off filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					<SelectItem value="yes">Signed on</SelectItem>
					<SelectItem value="no">Signed off</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
const GridOnBoardFilter = ({
	value,
	onValueChange,
}: {
	value: string | undefined;
	onValueChange: (value: string) => void;
}) => {
	return (
		<Select value={value} onValueChange={onValueChange}>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Onboard filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					<SelectItem value="yes">Yes</SelectItem>
					<SelectItem value="no">No</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
const GridSearch = ({
	value,
	onValueChange,
}: {
	value: string | undefined;
	onValueChange: (value: string) => void;
}) => {
	return (
		<Input
			placeholder="Search..."
			value={value}
			onChange={(e) => onValueChange(e.target.value)}
			className="max-w-sm"
		/>
	);
};
