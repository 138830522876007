"use client";

import { Button } from "@/components/ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	useReactTable,
	type ColumnFiltersState,
	type Row,
} from "@tanstack/react-table";

import { CrewMembersDataControls } from "@/components/CrewMembersDataControls";
import { useAuth } from "@/hooks/useAuth";
import { type User } from "@/types/User";

import { useDateFormatter } from "@/hooks/useDateFormatter";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getColumns } from "./columns";

type Props = {
	data: User[];
};

function globalFilterFn(row: Row<User>, _columnId: string, query: string) {
	const lowercasedQuery = query.toLowerCase();
	const name: string = row.getValue("name");
	const contact: string = row.getValue("contact");

	return (
		name.toLowerCase().includes(lowercasedQuery) || contact.toLowerCase().includes(lowercasedQuery)
	);
}

// const joyrideHeadersIdsDictionary: { [key: string]: string } = {
// 	rotation: "crew-member-rotation-head",
// 	rotationPartner: "crew-member-rotation-partner-head",
// };

export const CrewMembersTable = ({ data }: Props) => {
	// Get search parameters from the URL
	const [searchParams, setSearchParams] = useSearchParams();

	const { format } = useDateFormatter();
	const auth = useAuth();
	const user = auth?.user;
	const role = user?.role.name;

	// Initialize state from URL query params
	const [pagination, setPagination] = useState(() => ({
		pageIndex: Number(searchParams.get("pageIndex")) || 0,
		pageSize: Number(searchParams.get("pageSize")) || 10,
	}));

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(() => {
		const filters = searchParams.get("columnFilters");
		return filters ? JSON.parse(filters) : [];
	});

	const [globalFilter, setGlobalFilter] = useState(() => {
		return searchParams.get("globalFilter") ?? "";
	});

	// Update URL query parameters when state changes
	useEffect(() => {
		const params = new URLSearchParams();
		params.set("pageIndex", pagination.pageIndex.toString());
		params.set("pageSize", pagination.pageSize.toString());
		if (columnFilters.length > 0) {
			params.set("columnFilters", JSON.stringify(columnFilters));
		} else {
			params.delete("columnFilters");
		}
		if (globalFilter) {
			params.set("globalFilter", globalFilter);
		} else {
			params.delete("globalFilter");
		}
		setSearchParams(params);
	}, [pagination, columnFilters, setSearchParams]);

	const table = useReactTable({
		data,
		columns: getColumns(role as string, user?.id as number, format),
		onColumnFiltersChange: setColumnFilters,
		onPaginationChange: setPagination,
		onGlobalFilterChange: setGlobalFilter,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			columnFilters,
			pagination,
			globalFilter,
		},
		globalFilterFn: globalFilterFn,
	});

	return (
		<div className="w-full">
			<CrewMembersDataControls table={table} />
			<div className="rounded-md">
				<Table id="crew-members-table">
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead
											key={header.id}
											// id={
											// 	joyrideHeadersIdsDictionary[header.id]
											// 		? joyrideHeadersIdsDictionary[header.id]
											// 		: undefined
											// }
											style={{ width: `${header.getSize()}px` }}
										>
											{header.isPlaceholder
												? null
												: flexRender(header.column.columnDef.header, header.getContext())}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
									{row.getVisibleCells().map((cell) => (
										<TableCell
											key={cell.id}
											style={{ width: `${cell.column.getSize()}px` }}
											className="align-top"
										>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									colSpan={getColumns(role as string, user?.id as number, format).length}
									className="h-24 text-center"
								>
									No results.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<div className="flex items-center justify-end space-x-2 py-4">
				<div className="space-x-2">
					<Button
						variant="outline"
						size="sm"
						onClick={() => table.previousPage()}
						disabled={!table.getCanPreviousPage()}
					>
						Previous
					</Button>
					<Button
						variant="outline"
						size="sm"
						onClick={() => table.nextPage()}
						disabled={!table.getCanNextPage()}
					>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
};
