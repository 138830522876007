import { Pen } from "lucide-react";
import { useState } from "react";

import { useUpdateCrewMemberMutation } from "@/app/api/slices/crewMembersApiSlice";
import { UserUpsertForm } from "@/components/forms/UserUpsertForm";
import { type FormType } from "@/components/forms/UserUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { type User } from "@/types/User";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	userId: number;
	user: User;
};

export const EditCrewMemberDialog = ({ userId, user }: Props) => {
	const auth = useAuth();
	const role = auth?.user?.role?.name || "";
	const vesselId = useCurrentVesselId();

	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [updateCrewMemberMutation, { isLoading }] = useUpdateCrewMemberMutation();

	const handleUpdateCrewMember = async (data: FormType) => {
		await updateCrewMemberMutation({ vesselId, userId, data })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully updated crew member.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	// ROLE CHECK - HoD can't edit crew members
	if (["hod"].includes(role)) {
		return null;
	}

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button
					className="edit-crew-member-button"
					variant="outline"
					size="icon"
					style={{ background: "#2055A3" }}
				>
					<Pen color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Edit Crew Member</DialogTitle>
				</DialogHeader>
				<UserUpsertForm
					onSubmit={handleUpdateCrewMember}
					isLoading={isLoading}
					defaultValues={{
						...user,
						role: user.role.name,
						email: user?.email ? user.email : undefined,
						department: String(user?.department?.id),
						position: String(user?.position?.id),
						hod: user?.hod === 1 ? true : false,
						salary: user?.salary ? user.salary : undefined,
						startDay: user?.startDay ? new Date(user.startDay) : undefined,
						promotionDay: user?.promotionDay ? new Date(user.promotionDay) : undefined,
						annualLeaveAllowance: user?.annualLeaveAllowance
							? user.annualLeaveAllowance
							: undefined,
						currency: user?.currency ? user.currency : undefined,
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};
