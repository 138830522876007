import { type ColumnDef } from "@tanstack/react-table";
import { DayTypeDialog } from "@/components/dialogs/DayTypeDialog";
import { type FormatType } from "@/hooks/useDateFormatter";
import { type DayType } from "@/types/DayType";

export const getColumns = (
	format: FormatType,
): ColumnDef<DayType>[] => {
	const getLeaveBalanceStatus = (leave_balance_on_off: 0 | 1 | 2) => {
		switch(leave_balance_on_off) {
			case 0:
				return "Leave Neutral";
			case 1:
				return "Leave Accrue";
			case 2:
				return "Use Leave";
			default:
				return "-";
		}
	}

	return [
		{
			accessorKey: "name",
			accessorFn: (row) => `${row.name}`,
			header: "Name",
			cell: ({ row }) => {
				return (
					<div>{`${row.original.name}`}</div>
				);
			},
		},
		{
			accessorKey: "updatedAt",
			enableResizing: false,
			header: "Updated At",
			size: 208,
			minSize: 208,
			maxSize: 208,
			cell: ({ row }) => {
				return (
					<div>{format(row.original?.updated_at)}</div>
				);
			},
		},
		{
			accessorKey: "leave_balance_on_off",
			accessorFn: (row) => `${row.leave_type}`,
			header: "Leave Balance Calculation",
			cell: ({ row }) => {
				return (
					<div >
						{getLeaveBalanceStatus(row.original?.leave_type)}
					</div>
				);
			},
		},
		{
			id: "actions",
			header: () => <div>Actions</div>,
			cell: ({ row }) => {
				return (
					<div className="flex justify-center gap-2">
						<DayTypeDialog dayTypeData={{ ...row.original }} />
					</div>
				);
			},
		},
	];
};
