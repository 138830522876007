"use client";

import { Button } from "@/components/ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	useReactTable,
	type ColumnFiltersState,
} from "@tanstack/react-table";

import { VesselsTableDataControls } from "@/components/VesselsTableDataControls";
import { type Vessel } from "@/types/Vessel";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { columns } from "./columns";

type Props = {
	data: Vessel[];
};

export const VesselsTable = ({ data }: Props) => {
	// Get search parameters from the URL
	const [searchParams, setSearchParams] = useSearchParams();

	// Initialize state from URL query params
	const [pagination, setPagination] = useState(() => ({
		pageIndex: Number(searchParams.get("pageIndex")) || 0,
		pageSize: Number(searchParams.get("pageSize")) || 10,
	}));

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(() => {
		const filters = searchParams.get("columnFilters");
		return filters ? JSON.parse(filters) : [];
	});

	// Update URL query parameters when state changes
	useEffect(() => {
		const params = new URLSearchParams();
		params.set("pageIndex", pagination.pageIndex.toString());
		params.set("pageSize", pagination.pageSize.toString());
		if (columnFilters.length > 0) {
			params.set("columnFilters", JSON.stringify(columnFilters));
		} else {
			params.delete("columnFilters");
		}
		setSearchParams(params);
	}, [pagination, columnFilters, setSearchParams]);

	const table = useReactTable({
		data,
		columns,
		onColumnFiltersChange: setColumnFilters,
		onPaginationChange: setPagination,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			columnFilters,
			pagination,
		},
	});

	return (
		<div className="w-full">
			<VesselsTableDataControls table={table} />
			<div className="rounded-md">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(header.column.columnDef.header, header.getContext())}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
									{row.getVisibleCells().map((cell) => {
										const hasMeta = cell.getContext().cell.column.columnDef.meta;

										return (
											<TableCell
												key={cell.id}
												{...(hasMeta && { ...hasMeta.getCellContext(cell.getContext()) })}
											>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</TableCell>
										);
									})}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={columns.length} className="h-24 text-center">
									No results.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<div className="flex items-center justify-end space-x-2 py-4">
				<div className="space-x-2">
					<Button
						variant="outline"
						size="sm"
						onClick={() => table.previousPage()}
						disabled={!table.getCanPreviousPage()}
					>
						Previous
					</Button>
					<Button
						variant="outline"
						size="sm"
						onClick={() => table.nextPage()}
						disabled={!table.getCanNextPage()}
					>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
};
