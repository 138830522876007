import { useState, useMemo } from "react";

import { useGetGlobalDayTypesQuery } from "@/app/api/slices/dayTypeApiSlice";

import { InLayoutLoader } from "@/components/InLayoutLoader";
// import { TableGridViewSwitcher } from "@/components/TableGridViewSwitcher";
import { WhiteBox } from "@/components/WhiteBox";
// import { DayTypeGrid } from "@/components/grids/DayTypeGrid";
import { VesselsSettingsDayTypesTable } from "@/components/tables/VesselsSettingsDayTypesTable";

export const VesselsSettingsDayTypesListingPage = () => {
	const { data: response, isLoading, isFetching } = useGetGlobalDayTypesQuery({});

	const preparedData = useMemo(() => {
		if (!response?.data) {
			return [];
		}

		return Object.entries(response.data).map(([key, value]) => {
			return {
				id: key,
				name: value as string,
			}
		});
	}, [response]);

	const [currentView, _setCurrentView] = useState<"table" | "grid">("table");
	// const handleViewSwitch = () => {
	// 	setCurrentView(currentView === "table" ? "grid" : "table");
	// };


	if (isLoading || isFetching) {
		return (
			<div>
				<InLayoutLoader />
			</div>
		);
	}

	return (
		<WhiteBox className="mt-4">
			<div className="mb-4 flex justify-between">
				<h1 className="text-xl font-semibold md:text-2xl">
					Settings - Day Types
				</h1>
				{/* <TableGridViewSwitcher onClick={handleViewSwitch} /> */}
			</div>
			{currentView === "table" && <VesselsSettingsDayTypesTable data={preparedData} />}
			{/* {currentView === "grid" && <DayTypeGrid data={response?.data?.dayTypes || []} />} */}
		</WhiteBox>
	);
};
