import {
	useGetTerminalLoginStatusQuery,
	useUpdateLoginInTerminalModeMutation,
} from "@/app/api/slices/authApiSlice";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { useNavigate } from "react-router-dom";

import { authSlice } from "@/app/slices/authSlice";
import { TerminalLoginForm } from "@/components/forms/TerminalLoginForm";
import { FormType } from "@/components/forms/TerminalLoginForm/formSchema";
import { Logo } from "@/components/Logo";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const TerminalLoginPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { toast } = useToast();
	const [updateLoginInTerminalModeMutation, { isLoading }] = useUpdateLoginInTerminalModeMutation();

	const [step, setStep] = useState(0);
	const [loginCode, setLoginCode] = useState<string | null>(null);

	const {
		data: statusResponse,
		error: statusError,
		isLoading: isLoadingStatus,
		isFetching: isFetchingStatus,
	} = useGetTerminalLoginStatusQuery(
		{ code: loginCode },
		{
			pollingInterval: 3000, // 3 seconds
			skip: step !== 1 || !loginCode,
		},
	);

	const handleLogin = async ({ code }: FormType) => {
		await updateLoginInTerminalModeMutation({ code, data: { code, status: "pending" } })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "Login has to be accepted by manager.",
				});
				setLoginCode(code);
				setStep(1);
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	// track status
	useEffect(() => {
		// login user if status is accepted
		if (statusResponse?.data?.user) {
			dispatch(authSlice.actions.terminalLogin({ user: statusResponse.data.user }));
			navigate("/crew-members");
		}
	}, [statusResponse, isLoadingStatus, isFetchingStatus]);

	// handle error
	useEffect(() => {
		if (statusError) {
			handleServerErrors(statusError, toast);
		}
	}, [statusError]);

	return (
		<div className="flex min-h-screen items-center justify-center">
			<Card className="w-[600px]">
				<CardHeader>
					<div className="mb-4 flex justify-center">
						<Logo />
					</div>
					<CardTitle className="text-center">Terminal Login</CardTitle>
				</CardHeader>
				<CardContent>
					{step === 0 && <TerminalLoginForm onSubmit={handleLogin} isLoading={isLoading} />}
					{step === 1 && <AwaitingForConfirmation />}
				</CardContent>
			</Card>
		</div>
	);
};

const AwaitingForConfirmation = () => {
	return (
		<div className="mb-2 text-center text-xl">
			<p>Waiting for confirmation...</p>
		</div>
	);
};
