import * as z from "zod";

export const baseSchema = z.object({
	name: z.string().min(1, { message: "Name is required" }),
	key: z.string().min(1, { message: "Key is required" }),
});

export type FormType = z.infer<typeof baseSchema>;

export const getFormSchema = () => (baseSchema);
