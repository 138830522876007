import { type DayType } from "@/types/DayType";
import { GridItem } from "./GridItem";

export const DayTypeGrid = ({ data }: {data: DayType[]}) => {
	return (
		<div className="w-full">
			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
				{data.map((dayType) => {
					return <GridItem key={dayType.id} dayType={dayType} />;
				})}
			</div>
		</div>
	);
};
