import { useEffect, useState } from "react";

import { useGetVesselQuery } from "@/app/api/slices/admin/vesselsApiSlice";
import { useGetCrewMembersQuery } from "@/app/api/slices/crewMembersApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { TableGridViewSwitcher } from "@/components/TableGridViewSwitcher";
import { WhiteBox } from "@/components/WhiteBox";
import { CrewMembersGrid } from "@/components/grids/CrewMembersGrid";
import { CrewMembersTable } from "@/components/tables/CrewMembersTable";
import { useJoyride } from "@/contexts/JoyrideContext";
import { joyride_redirect_3_index } from "@/hocs/JoyrideWrapper";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";

export const CrewMembersListingPage = () => {
	const auth = useAuth();
	const isTerminal = auth?.user?.role.name === "terminal";
	const vesselId = useCurrentVesselId();

	const { data: response, isLoading, isFetching } = useGetCrewMembersQuery({ vesselId });
	const {
		data: vesselResponse,
		isLoading: isLoadingVessel,
		isFetching: isFetchingVessel,
	} = useGetVesselQuery({ vesselId });

	const [currentView, setCurrentView] = useState<"table" | "grid">(isTerminal ? "grid" : "table");
	const handleViewSwitch = () => {
		setCurrentView(currentView === "table" ? "grid" : "table");
	};

	const { run, startJoyride, stepIndex } = useJoyride();
	useEffect(() => {
		if (!run && !isLoading && !isLoadingVessel && stepIndex === joyride_redirect_3_index) {
			setTimeout(() => {
				startJoyride();
			}, 400);
		}
	}, [isLoading, isLoadingVessel]);

	if (isLoading || isFetching || isLoadingVessel || isFetchingVessel)
		return (
			<div id="crew-listing-box">
				<InLayoutLoader />
			</div>
		);

	return (
		<WhiteBox id="crew-listing-box" className="mt-4">
			<div className="mb-4 flex justify-between">
				<h1 className="text-xl font-semibold md:text-2xl">
					{isTerminal ? "Crew In/Out Board " : "Crew Members "}
					{!isTerminal && (
						<span>
							({response?.data?.length || 0}/
							{vesselResponse?.data?.subscription?.tier?.usersLimit || 0})
						</span>
					)}
				</h1>
				{!isTerminal && <TableGridViewSwitcher onClick={handleViewSwitch} />}
			</div>
			{currentView === "table" && <CrewMembersTable data={response?.data || []} />}
			{currentView === "grid" && <CrewMembersGrid data={response?.data || []} />}
		</WhiteBox>
	);
};
