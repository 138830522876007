import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
// import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { type FormProps } from "@/types/Form";
import { baseSchema, type FormType } from "./formSchema";

type Props = FormProps<FormType>;

export const DayTypeUpdateForm = ({
	onSubmit,
	isLoading,
	defaultValues = {
		leave_type: '',
	},
}: Props) => {
	const form = useForm<FormType>({
		resolver: zodResolver(baseSchema),
		defaultValues,
	});
	const { handleSubmit, control } = form;

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					{/* <div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Name</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div> */}
					
					<div className={`flex flex-col space-y-1.5`}>
						<FormField
							control={control}
							name="leave_type"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Leave type</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Select
												onValueChange={field.onChange}
												defaultValue={field.value}
											>
												<SelectTrigger>
													<SelectValue placeholder="Select Role" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectItem value="0">Leave Neutral</SelectItem>
														<SelectItem value="1">Leave Accrue</SelectItem>
														<SelectItem value="2">Use Leave</SelectItem>
													</SelectGroup>
												</SelectContent>
											</Select>
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					
					<div className="flex justify-between">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Cancel
							</Button>
						</DialogClose>
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Save
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};
