/* eslint-disable no-async-promise-executor */
import { type Editor } from "@ckeditor/ckeditor5-core";
import { type FileLoader, type UploadAdapter } from "@ckeditor/ckeditor5-upload/src/filerepository";
import axios from "axios";

const BASE_URL = import.meta.env.VITE_API_URL;

function uploadAdapter(loader: FileLoader): UploadAdapter {
	return {
		upload: () => {
			return new Promise(async (resolve, reject) => {
				try {
					const file = await loader.file;
					const response = await axios.request({
						method: "POST",
						url: `${BASE_URL}/api/uploadFile`,
						data: {
							file: file,
						},
						headers: {
							"Content-Type": "multipart/form-data",
						},
						withCredentials: true,
					});
					resolve({
						default: `${BASE_URL}/${response.data.data.url}`,
					});
				} catch (error) {
					reject("Something went wrong. Try again.");
				}
			});
		},
		abort: () => {},
	};
}

export function uploadPlugin(editor: Editor) {
	editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
		// @ts-ignore
		return uploadAdapter(loader);
	};
}
