import { useSearchParams } from "react-router-dom";

export function useURLFilters() {
	const [searchParams, setSearchParams] = useSearchParams();

	// Global search filter from URL (or empty string)
	const globalFilter = searchParams.get("globalFilter") || "";

	// Default filter values
	let departmentFilter = "all";
	let signedOnFilter = "all";
	let onBoardFilter = "all";

	// Parse the "columnFilters" JSON from URL (if any)
	const columnFiltersStr = searchParams.get("columnFilters");
	if (columnFiltersStr) {
		try {
			const filters = JSON.parse(columnFiltersStr);
			filters.forEach((filter: { id: string; value: string | boolean }) => {
				if (filter.id === "department") {
					departmentFilter = filter.value as string;
				}
				if (filter.id === "isSignedOn") {
					signedOnFilter = filter.value === true ? "yes" : "no";
				}
				if (filter.id === "isOnboard") {
					onBoardFilter = filter.value === true ? "yes" : "no";
				}
			});
		} catch (e) {
			console.error("Error parsing columnFilters:", e);
		}
	}

	// Helpers to update filters in URL
	const setGlobalFilter = (value: string) => {
		const newParams = new URLSearchParams(searchParams);
		if (value) newParams.set("globalFilter", value);
		else newParams.delete("globalFilter");
		setSearchParams(newParams);
	};

	const setColumnFilter = (id: string, value: boolean | string | undefined) => {
		// Read current filters
		let filters = [];
		const colFiltersStr = searchParams.get("columnFilters");
		if (colFiltersStr) {
			try {
				filters = JSON.parse(colFiltersStr);
			} catch (e) {
				console.error("Error parsing columnFilters:", e);
			}
		}
		// Remove existing filter for this id
		filters = filters.filter((f: { id: string; value: string | boolean }) => f.id !== id);
		// Add new filter if value is not the default
		if (value !== undefined && value !== "all" && value !== "") {
			filters.push({ id, value });
		}
		const newParams = new URLSearchParams(searchParams);
		if (filters.length > 0) newParams.set("columnFilters", JSON.stringify(filters));
		else newParams.delete("columnFilters");
		setSearchParams(newParams);
	};

	return {
		globalFilter,
		departmentFilter,
		signedOnFilter,
		onBoardFilter,
		setGlobalFilter,
		setColumnFilter,
	};
}
