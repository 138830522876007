import {
	useGetTerminalLoginStatusQuery,
	useLoginInTerminalModeMutation,
	useUpdateLoginInTerminalModeMutation,
} from "@/app/api/slices/authApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { Copy } from "lucide-react";
import { useEffect, useState } from "react";

type StatusKey = "pending" | "created" | "accepted" | "error" | "unknown";
const statuses: Record<StatusKey, { text: string; className: string }> = {
	pending: {
		text: "pending",
		className: "text-yellow-500",
	},
	created: {
		text: "created",
		className: "text-yellow-500",
	},
	accepted: {
		text: "accepted",
		className: "text-green-500",
	},
	error: {
		text: "error",
		className: "text-red-500",
	},
	unknown: {
		text: "unknown",
		className: "text-gray-500",
	},
};

export const TerminalModeSettings = () => {
	const { toast } = useToast();
	const auth = useAuth();
	const role = auth?.user?.role.name || "";
	const [triggerLoginInTerminalModeMutation, { isLoading: isTriggering }] =
		useLoginInTerminalModeMutation();
	const [updateLoginInTerminalModeMutation, { isLoading: isConfirming }] =
		useUpdateLoginInTerminalModeMutation();

	const [step, setStep] = useState(0);
	const [loginCode, setLoginCode] = useState<string | null>(null);
	const [loginStatus, setLoginStatus] = useState<StatusKey>("unknown");

	const {
		data: statusResponse,
		error: statusError,
		isLoading: isLoadingStatus,
		isFetching: isFetchingStatus,
	} = useGetTerminalLoginStatusQuery(
		{ code: loginCode },
		{
			pollingInterval: 3000, // 3 seconds
			skip: step !== 1 || !loginCode,
		},
	);

	if (!["manager", "fleet_manager"].includes(role)) return null;

	const handleTriggerLoginInTerminalMode = async () => {
		await triggerLoginInTerminalModeMutation({})
			.unwrap()
			.then((payload) => {
				// pass the login code to user
				setLoginCode(payload?.data?.code);
				setLoginStatus(payload?.data?.status);

				toast({
					variant: "success",
					title: "Enter this code as login in terminal mode login.",
				});

				// start polling for the login status
				setStep(1);
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	const handleConfirmLoginInTerminalMode = async () => {
		await updateLoginInTerminalModeMutation({
			code: loginCode,
			data: {
				code: loginCode,
				status: "accepted",
			},
		})
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "Login confirmed.",
				});

				// reset state
				setLoginCode(null);
				setLoginStatus("unknown");
				setStep(0);
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	// track status response
	useEffect(() => {
		setLoginStatus(statusResponse?.data?.status || "unknown");
	}, [statusResponse, isLoadingStatus, isFetchingStatus]);

	// track status state
	useEffect(() => {
		if (loginStatus === "pending" && step !== 2) {
			setStep(2);
		}
	}, [loginStatus]);

	// handle error
	useEffect(() => {
		if (statusError) {
			handleServerErrors(statusError, toast);
		}
	}, [statusError]);

	return (
		<div className="mb-2">
			<h2 className="mt-4 mb-4 text-xl font-semibold sm:mt-0 md:text-2xl">Terminal Mode</h2>
			{/* step 0 */}
			{step === 0 && (
				<StepTriggerLogin onClick={handleTriggerLoginInTerminalMode} isLoading={isTriggering} />
			)}
			{/* step 1 */}
			{step === 1 && (
				<>
					<StepLoginCode code={loginCode} />
					<CheckStatusIndicator status={loginStatus} />
				</>
			)}
			{/* step 2 */}
			{step === 2 && (
				<StepAcceptLogin onClick={handleConfirmLoginInTerminalMode} isLoading={isConfirming} />
			)}
		</div>
	);
};

const StepTriggerLogin = ({ onClick, isLoading }: { onClick: () => void; isLoading: boolean }) => {
	return (
		<Button onClick={onClick} disabled={isLoading}>
			<ButtonLoader isLoading={isLoading} />
			Login in Terminal Mode
		</Button>
	);
};

const StepLoginCode = ({ code }: { code: string | null }) => {
	const [copied, setCopied] = useState(false);

	const handleCopy = async () => {
		if (!code) {
			return;
		}

		try {
			await navigator.clipboard.writeText(code);
			setCopied(true);
			setTimeout(() => setCopied(false), 2000);
		} catch (err) {
			console.error("Failed to copy:", err);
		}
	};

	return (
		<div>
			<h3 className="mb-1">
				Enter this code as login in terminal mode login ({window.location.host}/terminal).
			</h3>
			<div className="flex items-center gap-2">
				<blockquote className="border-ground border-l-2 bg-gray-100 p-1 pr-2 text-3xl">
					{code}
				</blockquote>
				<Button onClick={handleCopy} size="icon" variant="outline">
					<Copy className="h-4 w-4" />
				</Button>
				{copied && <span className="text-sm text-green-600">Copied!</span>}
			</div>
		</div>
	);
};

const CheckStatusIndicator = ({ status }: { status: StatusKey }) => {
	const statusInfo = statuses[status] || statuses.unknown;

	return (
		<span>
			Status: <span className={statusInfo.className}>{statusInfo.text}</span>
		</span>
	);
};

const StepAcceptLogin = ({ onClick, isLoading }: { onClick: () => void; isLoading: boolean }) => {
	return (
		<Button onClick={onClick} disabled={isLoading} className="bg-green-600 hover:bg-green-700">
			<ButtonLoader isLoading={isLoading} />
			Confirm login in Terminal Mode
		</Button>
	);
};
