import { Button } from "@/components/ui/button";
import { GitFork, TableColumnsSplit, Users } from "lucide-react";
import { Link } from "react-router-dom";

const buttons = [
	{
		id: "menu-positions",
		title: "Crew Leave Schedule",
		subtitle: null,
		icon: <TableColumnsSplit />,
		link: "/ship-calendar",
	},
	{
		id: "menu-positions",
		title: "Positions Calendar",
		subtitle: null,
		icon: <GitFork />,
		link: "/positions",
	},
	{
		id: "menu-crew",
		title: "Crew List",
		subtitle: null,
		icon: <Users />,
		link: "/crew-members",
	},
];

export const DashboardSideButtons = () => {
	return (
		<div className="flex flex-col gap-2">
			{buttons.map((item) => {
				return (
					<Button asChild key={item.title} className={`flex items-center justify-start gap-3`}>
						<Link to={item.link} id={item.id} target="_blank">
							<div className="ml-1">{item.icon}</div>
							<div className="flex flex-col items-start overflow-hidden transition-all duration-300">
								{item.title}
							</div>
						</Link>
					</Button>
				);
			})}
		</div>
	);
};
