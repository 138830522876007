// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as Tooltip from '@radix-ui/react-tooltip';
import { format as formatFns } from "date-fns";

import { CHANGE_DAYS } from "@/utils/calendarUtils";
import RotatLogo from "@/assets/logo-small.svg";
import { useGetAppConfigQuery } from "@/app/api/slices/appConfigApiSlice";
import { useGetCellColor } from "@/hooks/useGetCellColor";
import { MonthChange } from '../calendar/Cell/MonthChange';
import { useDateFormatter } from '@/hooks/useDateFormatter';
import { type Notes } from "@/types/Calendar";
import { Info as InfoIcon } from "lucide-react";

interface FormattedDocuments {
	[key: string]: {
		title: string;
		id: string | number;
	};
}

type Props = {
	isToday: boolean;
	documents: FormattedDocuments[] | undefined | object;
	type: string; 
	date: Date; 
	weekYear: number; 
	clickHandler: ({startDate, dayKind}:{startDate: Date, dayKind: string}) => void;
	setHoveredCell: (cell: string | undefined) => void;
	onClickDocument: (id: string | number) => void;
	className?: string;
	notes: undefined | Notes;
};

export const Cell = ({ isToday=false, className="", onClickDocument, documents, setHoveredCell, type, date, clickHandler, notes}: Props) => {
	const { getClassFromType } = useGetCellColor();
	const { format } = useDateFormatter();

	const { data: configData } = useGetAppConfigQuery({});
	const formattedDate = date ? formatFns(date, "y-MM-dd") : "-";

	const day = date.toLocaleString("default", { day: "numeric" });

	const notesIds = notes?.dates[formattedDate];

	return (
		<Tooltip.Provider>
			<Tooltip.Root>
				<Tooltip.Trigger asChild>
					<div className={`ctrl__cell-day grow relative cursor-pointer flex items-center justify-center rounded-md border border-gray-300 font-medium text-xs lg:text-sm ${isToday ? "border-red-500 calendar-current-day" : ""} ${className}`}
						style={{background: className ? "" : getClassFromType(type)}}
						onClick={() => clickHandler({startDate: date, dayKind: type})}
						onMouseEnter={() => setHoveredCell(formatFns(date, "y-MM-dd"))}
      					onMouseLeave={() => setHoveredCell(undefined)}
						data-cell-date={formatFns(date, "y-MM-dd")}
					>
						<div className="relative w-full h-full flex justify-center items-center font-medium overflow-hidden rounded-md">
							{(CHANGE_DAYS.includes(type)) && (
								<div className="text-sm">
									<img src={RotatLogo} alt="Rotat Logo" className="w-4 h-4 inline-block mr-1"/>
								</div>
							)}

							<span>{day}</span>

							{ (documents && documents[formattedDate]) && (
								<div className="top-0 left-0 right-0 absolute h-2 bg-red-500 rotate-[45deg] translate-x-2/4"></div>
							)}

							{ notesIds && (
								<div className="top-0 left-0 right-0 absolute h-2 bg-blue-500 rotate-[-45deg] translate-x-[-50%]"></div>
							)}
						</div>

						<MonthChange day={day}/>
					</div>
				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content className="TooltipContent" sideOffset={5}>

						<div className="flex flex-col">
							<div className="text-xs lg:text-sm font-medium capitalize">{configData?.data?.day_types?.[type]}</div>
							<div className="text-xs lg:text-sm">{format(formattedDate)}</div>

							{CHANGE_DAYS.includes(type) && (
								<div className="flex text-blue-500">
									<InfoIcon className="w-5 h-5 mr-1"/>
									<div>
										<div className="text-sm">Click to shift this day or</div>
										<div className="text-sm">double click to change day type</div>
									</div>
									
								</div>
							)}

							{ (documents && documents[formattedDate]) && (
								<div className="mt-2">
									<div className="text-xs lg:text-sm font-medium capitalize">Related documents: </div>
							
									{documents[formattedDate].map((doc: {id: string | number, title: string}) => {
										return (
											<div key={doc.id}>
												{/* <a href={doc.url} target="_blank" rel="noreferrer" className="text-xs lg:text-sm text-primary underline">{doc.name}</a> */}
												<p onClick={() => onClickDocument(doc.id)} className="text-xs lg:text-sm text-primary underline cursor-pointer">{doc.title}</p>
											</div>
										);
									})}
								</div>
							) }

							{notesIds && <div className="mt-2">Note{ notesIds.length > 1 ? 's' : ''}: </div>}
							{notesIds?.map((noteId: number) => {
								const note = notes?.notes[noteId].note;

								return (
									<div key={noteId} className="mt-1 p-1 bg-yellow-100 rounded">
										<p className="text-xs lg:text-sm cursor-pointer">{note}</p>
									</div>
								);
							})}

						</div>

					</Tooltip.Content>
				</Tooltip.Portal>
      		</Tooltip.Root>
    	</Tooltip.Provider>
	);
};
