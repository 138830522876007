import { type ColumnDef } from "@tanstack/react-table";
import { VesselsSettingsDayTypesEditDialog } from "@/components/dialogs/VesselsSettingsDayTypesEditDialog";
import { VesselsSettingsDayTypesDeleteDialog } from "@/components/dialogs/VesselsSettingsDayTypesDeleteDialog";

export const getColumns = (): ColumnDef<{id: string | number, name: string}>[] => {
	return [
		{
			accessorKey: "name",
			accessorFn: (row) => `${row.name}`,
			header: "Name",
			cell: ({ row }) => {
				return (
					<div>{`${row.original.name}`}</div>
				);
			},
		},
		{
			id: "actions",
			header: () => <div>Actions</div>,
			cell: ({ row }) => {
				return (
					<div className="flex justify-center gap-2">
						<VesselsSettingsDayTypesEditDialog dayTypeData={{ ...row.original }} />
						<VesselsSettingsDayTypesDeleteDialog  dayId={row.original.id}/>
					</div>
				);
			},
		},
	];
};
