import { CrewMembersDataControls } from "@/components/CrewMembersDataControls";
import { type User } from "@/types/User";

import { useURLFilters } from "@/hooks/useCrewMembersURLFilters";
import { useEffect, useState } from "react";
import { GridItem } from "./GridItem";

type Props = {
	data: User[];
};

export const CrewMembersGrid = ({ data }: Props) => {
	const [visibleData, setVisibleData] = useState(data);

	// Read unified filters from URL.
	const { globalFilter, departmentFilter, signedOnFilter, onBoardFilter } = useURLFilters();

	useEffect(() => {
		const filtered = data.filter((crewMember) => {
			// Department filter
			if (departmentFilter !== "all" && crewMember.department?.name !== departmentFilter) {
				return false;
			}
			// Signed on/off filter
			if (signedOnFilter !== "all" && crewMember.isSignedOn !== (signedOnFilter === "yes")) {
				return false;
			}
			// Onboard filter
			if (onBoardFilter !== "all" && crewMember.isOnboard !== (onBoardFilter === "yes")) {
				return false;
			}
			// Global search filter: match against firstName, lastName, phone, or email.
			if (globalFilter) {
				const searchLower = globalFilter.toLowerCase();
				if (
					!crewMember.firstName.toLowerCase().includes(searchLower) &&
					!crewMember.lastName.toLowerCase().includes(searchLower) &&
					!crewMember.phone.toLowerCase().includes(searchLower) &&
					!(crewMember.email && crewMember.email.toLowerCase().includes(searchLower))
				) {
					return false;
				}
			}
			return true;
		});
		setVisibleData(filtered);
	}, [data, globalFilter, departmentFilter, signedOnFilter, onBoardFilter]);

	return (
		<div className="w-full">
			<CrewMembersDataControls />
			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
				{visibleData.map((crewMember) => {
					return <GridItem key={crewMember.id} crewMember={crewMember} />;
				})}
			</div>
		</div>
	);
};
