import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { selectAuthStatus } from "@/app/slices/authSlice";
import { FullscreenLoader } from "@/components/FullscreenLoader";
import { useAuth } from "@/hooks/useAuth";

export function ProtectedManagerOutlet() {
	const auth = useAuth();
	const location = useLocation();
	const authStatus = useSelector(selectAuthStatus);

	if (authStatus === "idle" || authStatus === "loading") {
		return <FullscreenLoader />;
	}

	const user = auth?.user;

	if (!user) {
		return <Navigate to="/login" state={{ from: location }} />;
	}

	const isManager = ["manager", "fleet_manager", "hod"].includes(user?.role.name);
	const isTerminal = user?.role.name === "terminal";

	if (isTerminal) {
		return location.pathname === "/crew-members" ? <Outlet /> : <Navigate to="/crew-members" />;
	}

	return user && isManager ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
}
