import { useCallback } from "react";

import {
	useGetCalendarColorsQuery
} from "@/app/api/slices/appConfigApiSlice";

export function useGetCellColor() {
    const { data: calendarColors, isLoading } = useGetCalendarColorsQuery({});

    const getClassFromType = useCallback((type: string) => {
        if(!calendarColors || type === undefined || type === "undefined") return "";

        if(type === "highlight") {
            return "#FB923C";
        }
        
        const color = calendarColors?.data?.[type];
        return  color ? `${color}` : "#E7862F";
    }, [calendarColors]);

	return {
        getClassFromType,
        isLoading
    }
}
