import OneSignal from "react-onesignal";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useLoginMutation, useSetPasswordMutation } from "@/app/api/slices/authApiSlice";
import { Logo } from "@/components/Logo";
import { ResetPasswordForm } from "@/components/forms/ResetPasswordForm";
import { type FormType } from "@/components/forms/ResetPasswordForm/formSchema";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

const WILL_LOGIN =
	"Your password has been successfully reset. You will now be logged in with your new password.";
const WILL_NOT_LOGIN =
	"Your password has been successfully reset. You can now log in with your new password.";

export const ResetPasswordPage = () => {
	const { toast } = useToast();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [loginMutation, { isLoading: isLoginLoading }] = useLoginMutation();
	const [resetPasswordMutation, { isLoading }] = useSetPasswordMutation();

	const token = searchParams.get("token");
	const email = searchParams.get("email");

	const handleResetPassword = async ({ password, confirmPassword }: FormType) => {
		await resetPasswordMutation({ token, email, password, confirmPassword })
			.unwrap()
			.then(async (payload) => {
				const user = payload?.data?.user;
				const userRole = user?.role?.name;

				toast({
					variant: "success",
					title: ["manager", "fleet_manager", "hod", "admin"].includes(userRole)
						? WILL_LOGIN
						: WILL_NOT_LOGIN,
				});

				if (user && userRole && ["manager", "fleet_manager", "hod", "admin"].includes(userRole)) {
					await loginMutation({
						email,
						password,
						loginType: userRole === "admin" ? "admin" : "manager",
					})
						.unwrap()
						.then((payload) => {
							const loggedUserId = String(payload.data.user.id);
							const loggedUserEmail = payload.data.user.email;
							OneSignal.login(loggedUserId);
							OneSignal.User.addEmail(loggedUserEmail);

							navigate("/");
						})
						.catch((error) => handleServerErrors(error, toast));
				}
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<div className="flex min-h-screen items-center justify-center">
			<Card className="w-[600px]">
				<CardHeader>
					<div className="mb-4 flex justify-center">
						<Logo />
					</div>
					<CardTitle className="text-center">Reset Password</CardTitle>
					<CardDescription className="text-center">
						Enter a new password below to regain access and enhance your account security.
					</CardDescription>
				</CardHeader>
				<CardContent>
					<ResetPasswordForm
						onSubmit={handleResetPassword}
						isLoading={isLoading || isLoginLoading}
					/>
				</CardContent>
			</Card>
		</div>
	);
};
