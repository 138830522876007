import { useState } from "react";

import { useGetDayTypesQuery } from "@/app/api/slices/dayTypeApiSlice";

import { InLayoutLoader } from "@/components/InLayoutLoader";
import { TableGridViewSwitcher } from "@/components/TableGridViewSwitcher";
import { WhiteBox } from "@/components/WhiteBox";
import { DayTypeGrid } from "@/components/grids/DayTypeGrid";
import { DayTypesTable } from "@/components/tables/DayTypesTable";

import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";

export const DayTypesListingPage = () => {
	const vesselId = useCurrentVesselId();

	const { data: response, isLoading, isFetching } = useGetDayTypesQuery({ vesselId }, { skip: !vesselId });

	const [currentView, setCurrentView] = useState<"table" | "grid">("table");
	const handleViewSwitch = () => {
		setCurrentView(currentView === "table" ? "grid" : "table");
	};


	if (isLoading || isFetching) {
		return (
			<div>
				<InLayoutLoader />
			</div>
		);
	}

	return (
		<WhiteBox className="mt-4">
			<div className="mb-4 flex justify-between">
				<h1 className="text-xl font-semibold md:text-2xl">
					Day Types
				</h1>
				<TableGridViewSwitcher onClick={handleViewSwitch} />
			</div>
			{currentView === "table" && <DayTypesTable data={response?.data?.dayTypes || []} />}
			{currentView === "grid" && <DayTypeGrid data={response?.data?.dayTypes || []} />}
		</WhiteBox>
	);
};
