import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useGetVesselSectionsQuery } from "@/app/api/slices/manager/managerKnowledgeBaseApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { Button } from "@/components/ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { uploadPlugin } from "@/lib/CKEditor";
import { type FormProps } from "@/types/Form";
import { convertYesNoToBoolean } from "@/utils/convertYesNoToBoolean";

import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { formSchema, type FormType } from "./formSchema";

export const KnowledgeBaseManagerArticleUpsertForm = ({
	onSubmit,
	isLoading,
	defaultValues = {
		title: "",
		description: "",
		section: "",
		isPublished: true,
		requireRead: false,
		content: "",
	},
}: FormProps<FormType>) => {
	const vesselId = useCurrentVesselId();

	const { data: vesselSectionsResponse, isLoading: isLoadingSections } = useGetVesselSectionsQuery({
		vesselId,
	});

	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: defaultValues,
	});
	const { handleSubmit, control } = form;

	const [sectionsOptions, setSectionsOptions] = useState([]);
	useEffect(() => {
		if (vesselSectionsResponse?.data.length > 0) {
			setSectionsOptions(vesselSectionsResponse?.data);
		}
	}, [vesselSectionsResponse]);

	if (isLoadingSections) {
		return <InLayoutLoader />;
	}

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="title"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Title</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="description"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-start gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Description</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Textarea {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="section"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Section</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Select onValueChange={field.onChange} defaultValue={field.value}>
												<SelectTrigger>
													<SelectValue />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														{sectionsOptions.length > 0 &&
															sectionsOptions.map((section: { id: number; name: string }) => (
																<SelectItem key={section.name} value={String(section.id)}>
																	{section.name}
																</SelectItem>
															))}
													</SelectGroup>
												</SelectContent>
											</Select>
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="isPublished"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Is published?</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Select
												onValueChange={(value) => {
													field.onChange(convertYesNoToBoolean(value));
												}}
												defaultValue={field.value ? "yes" : "no"}
											>
												<SelectTrigger>
													<SelectValue />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectItem value="yes">Yes</SelectItem>
														<SelectItem value="no">No</SelectItem>
													</SelectGroup>
												</SelectContent>
											</Select>
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="requireRead"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Require read?</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Select
												onValueChange={(value) => {
													field.onChange(convertYesNoToBoolean(value));
												}}
												defaultValue={field.value ? "yes" : "no"}
											>
												<SelectTrigger>
													<SelectValue />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectItem value="yes">Yes</SelectItem>
														<SelectItem value="no">No</SelectItem>
													</SelectGroup>
												</SelectContent>
											</Select>
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="content"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Content</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<div className="prose max-w-none">
												<CKEditor
													config={{
														extraPlugins: [uploadPlugin],
													}}
													editor={ClassicEditor}
													// @ts-ignore
													data={field.value}
													onReady={(_editor) => {
														// You can store the "editor" and use when it is needed.
														// console.log("Editor is ready to use!", editor);
													}}
													onChange={(_event, editor) => {
														field.onChange(editor.getData());
													}}
													// onBlur={(event, editor) => {
													// 	console.log("Blur.", editor);
													// }}
													// onFocus={(event, editor) => {
													// 	console.log("Focus.", editor);
													// }}
												/>
											</div>
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex justify-between">
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Save
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};
