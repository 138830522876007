import { endOfMonth, format as formatFns } from "date-fns";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useGetMyVesselInfoQuery } from "@/app/api/slices/manager/myVesselApiSlice";
import { useGetSubscriptionDetailsQuery } from "@/app/api/slices/manager/paymentsApiSlice";
import { DashboardSideButtons } from "@/components/DashboardSideButtons";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { VesselInfoBar } from "@/components/VesselInfoBar";
import { VesselStatistics } from "@/components/VesselStatistics";
import { WhiteBox } from "@/components/WhiteBox";
import { VesselCrewAccordion } from "@/components/accordions/VesselCrewAccordion";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogOverlay,
	DialogTitle,
} from "@/components/ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useToast } from "@/components/ui/use-toast";
import { useJoyride } from "@/contexts/JoyrideContext";
import { useNotificationBar } from "@/contexts/NotificationBarContext";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { cn } from "@/lib/utils";
import { type StringOpenStates } from "@/types/AccordionOpenStates";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { AlertTriangle, CalendarIcon, RefreshCcw } from "lucide-react";

export const MyVesselPage = () => {
	const auth = useAuth();
	const { toast } = useToast();
	const { format } = useDateFormatter();
	// const [notificationId, setNotificationId] = useState<number | null>(null);
	const { setNotificationBarMessage, removeNotificationBarMessage } = useNotificationBar();
	const vesselId = useCurrentVesselId();

	const now = new Date();
	const [date, setDate] = useState<Date | undefined>(endOfMonth(now));

	const {
		data: myVesselResponse,
		error: myVesselError,
		isLoading,
		isFetching,
	} = useGetMyVesselInfoQuery({
		param: date ? format(date) : "",
	});
	const [openStates, setOpenStates] = useState<StringOpenStates>({ "dep-0": true });

	const { data: response, isLoading: isLoadingSubscriptionDetails } =
		useGetSubscriptionDetailsQuery({ vesselId });

	const toggleOpenState = (sectionId: string) => {
		setOpenStates((prevStates) => ({
			...prevStates,
			[sectionId]: !prevStates[sectionId],
		}));
	};

	useEffect(() => {
		if (myVesselError) {
			handleServerErrors(myVesselError, toast);
		}
	}, [myVesselError]);

	useEffect(() => {
		// Remove existing notifications before showing a new one
		removeNotificationBarMessage("min-safe-manning-alert");
		// Check if there are insufficient onboard positions
		// If there are, show a notification
		if (myVesselResponse?.data?.insufficientOnboardPositions?.length > 0) {
			setNotificationBarMessage({
				id: "min-safe-manning-alert",
				message: (
					<>
						<p>
							<b>Minimum Safe Manning Alert:</b>
						</p>
						{myVesselResponse?.data?.insufficientOnboardPositions
							.map(
								({
									name,
									count,
									date,
									dateEnd,
								}: {
									name: string;
									count: number;
									date: string;
									dateEnd: string;
								}) => {
									const formattedDate = format(new Date(date));
									const formattedDateEnd = dateEnd ? ` to ${format(new Date(dateEnd))}` : "";
									return `${name} (${count} crew member${
										count > 1 ? "s" : ""
									}) from ${formattedDate}${formattedDateEnd}`;
								},
							)
							.join(" | ")}
					</>
				),
				type: "error",
			});
		}
	}, [myVesselResponse?.data?.insufficientOnboardPositions]);

	const [openNotification, setOpenNotification] = useState(false);
	useEffect(() => {
		const isDemoAccount = auth?.user?.isFromDemoAccount;

		if (!isDemoAccount && response && response?.data?.isActive !== 1) {
			setOpenNotification(true);
		}
	}, [response]);

	const { run, startJoyride, stepIndex } = useJoyride();
	useEffect(() => {
		if (!run && !isLoading && stepIndex === 0) {
			setTimeout(() => {
				startJoyride();
			}, 400);
		}
	}, [isLoading]);

	if (isLoading || isLoadingSubscriptionDetails) return <InLayoutLoader />;

	return (
		<>
			<VesselInfoBar />
			<div className="mt-4 grid grid-cols-12 gap-4">
				<div className="col-span-12 flex flex-col flex-wrap gap-4 xl:col-span-2">
					<WhiteBox id="vessel-crew-statistics">
						<h2 className="text-lg font-semibold">Vessel Statistics</h2>
						<VesselStatistics stats={myVesselResponse?.data?.summary} />
					</WhiteBox>
					{/* <WhiteBox id="vessel-departments-statistics">
						<h2 className="text-lg font-semibold">Statistics by department</h2>
						<VesselDepartmentsStatisticsAccordion
							openStates={openStates}
							toggleOpenState={toggleOpenState}
							departments={myVesselResponse.data.perDepartmentStats}
						/>
					</WhiteBox> */}
					<WhiteBox>
						<Link to="/day-types">
							<Button className="w-full">Manage Day Types</Button>
						</Link>
					</WhiteBox>
					<WhiteBox>
						<DashboardSideButtons />
					</WhiteBox>
				</div>
				<div className="col-span-12 xl:col-span-10">
					<WhiteBox id="vessel-departments-statistics-big-box">
						<AccordionDatePicker date={date} setDate={setDate} />
						<VesselCrewAccordion
							selectedDate={date}
							openStates={openStates}
							toggleOpenState={toggleOpenState}
							departments={myVesselResponse?.data?.crewInfo || []}
							leaveBalanceType={myVesselResponse?.data?.leave_balance_type}
							isLoading={isFetching}
						/>
					</WhiteBox>
				</div>
			</div>
			<Dialog open={openNotification} onOpenChange={setOpenNotification}>
				<DialogOverlay className="bg-red-500/40" />
				<DialogContent className="flex items-center justify-center overflow-y-hidden sm:max-w-[500px]">
					<div className="w-full text-center">
						<DialogHeader className="sm:text-center">
							<DialogTitle>Missing payment.</DialogTitle>
						</DialogHeader>
						<div className="mt-2 mb-4">Please complete your payment.</div>
						<div className="mb-4 flex items-center gap-4 rounded-md bg-red-200 p-2 text-left">
							<div className="shrink-0">
								<AlertTriangle color="red" />
							</div>
							<p>
								Please note that all payment processing is currently conducted through the Stripe
								sandbox for testing purposes. No real transactions will occur.
							</p>
							<div className="shrink-0">
								<AlertTriangle color="red" />
							</div>
						</div>
						<Button asChild>
							<Link to="/payments">Go to payments</Link>
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

const AccordionDatePicker = ({
	date,
	setDate,
}: {
	date: Date | undefined;
	setDate: (_value: Date | undefined) => void;
}) => {
	const now = new Date();

	return (
		<div className="mb-4 flex justify-start border-b pb-4">
			<div id="my-vessel-datepicker" className="flex items-center justify-start gap-2">
				<label className="flex items-center justify-center gap-2">
					Show crew changes:
					<Popover>
						<PopoverTrigger asChild>
							<Button
								variant={"outline"}
								className={cn(
									"w-[280px] justify-start text-left font-normal",
									!date && "text-muted-foreground",
								)}
							>
								<CalendarIcon className="mr-2 h-4 w-4" />
								{date ? formatFns(date, "PPP") : <span>Pick a date</span>}
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-auto p-0">
							<Calendar mode="single" selected={date} onSelect={setDate} initialFocus />
						</PopoverContent>
					</Popover>
				</label>
				<Button variant="outline" size="icon" onClick={() => setDate(endOfMonth(now))}>
					<RefreshCcw />
				</Button>
			</div>
		</div>
	);
};
