import { useEffect } from "react";
import OneSignal from "react-onesignal";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useLoginMutation, useMagicLoginMutation } from "@/app/api/slices/authApiSlice";
import { authSlice } from "@/app/slices/authSlice";
import { FullscreenLoader } from "@/components/FullscreenLoader";
import { Logo } from "@/components/Logo";
import { LoginForm } from "@/components/forms/LoginForm";
import { type FormType } from "@/components/forms/LoginForm/formSchema";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { useDispatch } from "react-redux";

export const ManagerLoginPage = () => {
	const dispatch = useDispatch();
	const { toast } = useToast();
	const navigate = useNavigate();
	const [loginMutation, { isLoading }] = useLoginMutation();
	const [magicLoginMutation, { isLoading: isLoadingMagicLogin }] = useMagicLoginMutation();
	const [searchParams] = useSearchParams();
	const magicLoginToken = searchParams.get("token");

	useEffect(() => {
		if (magicLoginToken) {
			magicLoginMutation({ token: magicLoginToken })
				.unwrap()
				.then((payload) => {
					dispatch(authSlice.actions.magicLogin(payload));
					const loggedUserId = String(payload.data.user.id);
					const loggedUserEmail = payload.data.user.email;
					OneSignal.login(loggedUserId);
					OneSignal.User.addEmail(loggedUserEmail);

					toast({
						variant: "success",
						title: "Successfully logged in with magic link.",
					});

					setTimeout(() => {
						navigate("/");
					}, 400);
				})
				.catch((error) => handleServerErrors(error, toast));
		}
	}, []);

	const handleLogin = async ({ email, password }: FormType) => {
		await loginMutation({ email, password, loginType: "manager" })
			.unwrap()
			.then((payload) => {
				const loggedUserId = String(payload.data.user.id);
				const loggedUserEmail = payload.data.user.email;
				OneSignal.login(loggedUserId);
				OneSignal.User.addEmail(loggedUserEmail);

				navigate("/");
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	if (magicLoginToken || isLoadingMagicLogin) {
		<FullscreenLoader />;
	}

	return (
		<div className="flex min-h-screen items-center justify-center">
			<Card className="w-[600px]">
				<CardHeader>
					<div className="mb-4 flex justify-center">
						<Logo />
					</div>
					<CardTitle className="text-center">Login</CardTitle>
				</CardHeader>
				<CardContent>
					<LoginForm onSubmit={handleLogin} isLoading={isLoading} />
				</CardContent>
			</Card>
		</div>
	);
};
