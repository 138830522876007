import { Button } from "@/components/ui/button";
import { HelpCircle } from "lucide-react";
import { useEffect } from "react";

export const StorylaneGuide = () => {
	// Load the external script when the component mounts.
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://js.storylane.io/js/v2/storylane.js";
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	const handleClick = () => {
		// Make sure Storylane is loaded before trying to use it
		if ((window as any).Storylane) {
			(window as any).Storylane.Play({
				type: "popup",
				demo_type: "image",
				width: 2560,
				height: 1440,
				scale: "0.95",
				demo_url: "https://app.storylane.io/demo/8x5ufy7chtxs?embed=popup",
				padding_bottom: "calc(56.25% + 25px)",
			});
		} else {
			console.error("Storylane is not loaded yet.");
		}
	};

	return (
		<Button
			onClick={handleClick}
			size="icon"
			className="sl-preview-cta relative hidden xl:inline-flex"
		>
			<HelpCircle />
		</Button>
	);
};
