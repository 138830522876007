import { useState } from "react";

import { VesselsSettingsDayTypeUpsertForm } from "@/components/forms/VesselsSettingsDayTypeUpsertForm";
import { type FormType } from "@/components/forms/VesselsSettingsDayTypeUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { useAddGlobalNewDayTypeMutation } from "@/app/api/slices/dayTypeApiSlice";

export const AddVesselsSettingsDayTypeDialog = () => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [addGlobalNewDayTypeMutation, { isLoading }] = useAddGlobalNewDayTypeMutation();

	const handleAddCrewMember = async (data: FormType) => {
		await addGlobalNewDayTypeMutation({ data })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added day type.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button
					id="add-crew-member-button"
					className="uppercase"
				>
					Add day type
				</Button>
			</DialogTrigger>
			<DialogContent
				id="add-crew-member-dialog"
				className="sm:max-w-[500px]"
				onInteractOutside={(e) => {
					e.preventDefault();
				}}
			>
				<DialogHeader>
					<DialogTitle>Add day type</DialogTitle>
				</DialogHeader>

				<VesselsSettingsDayTypeUpsertForm onSubmit={handleAddCrewMember} isLoading={isLoading} />

			</DialogContent>
		</Dialog>
	);
};
