import { apiSlice } from "@/app/api/apiSlice";

export const crewMembersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCrewMember: builder.query({
			query: ({ vesselId, userId }) => `/api/vessels/${vesselId}/users/${userId}`,
			providesTags: (_result, _error, { userId }) => [{ type: "Users", id: userId }],
		}),
		getCrewMembers: builder.query({
			query: ({ vesselId }) => `/api/vessels/${vesselId}/users`,
			providesTags: ["Users"],
		}),
		addCrewMember: builder.mutation({
			query: ({ vesselId, data }) => ({
				url: `/api/vessels/${vesselId}/users`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"Managers",
							"Users",
							"RotationCalendar",
							"RotationContracts",
							"RotationSettings",
							"PositionData",
							"AvailablePositions",
							"MyVesselInfo",
							"FleetUsers",
						],
		}),
		updateCrewMember: builder.mutation({
			query: ({ vesselId, userId, data }) => ({
				url: `/api/vessels/${vesselId}/users/${userId}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"Managers",
							"Users",
							"RotationCalendar",
							"RotationContracts",
							"RotationSettings",
							"PositionData",
							"AvailablePositions",
							"MyVesselInfo",
							"LeaveBalance",
							"FleetUsers",
						],
		}),
		removeCrewMember: builder.mutation({
			query: ({ vesselId, userId }) => ({
				url: `/api/vessels/${vesselId}/users/${userId}`,
				method: "DELETE",
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"Managers",
							"Users",
							"RotationCalendar",
							"RotationContracts",
							"RotationSettings",
							"PositionData",
							"AvailablePositions",
							"MyVesselInfo",
							"FleetUsers",
						],
		}),
		importCrewMember: builder.mutation({
			query: ({ vesselId, data }) => ({
				url: `/api/vessels/${vesselId}/users/import`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"Managers",
							"Users",
							"RotationCalendar",
							"RotationContracts",
							"RotationSettings",
							"PositionData",
							"AvailablePositions",
							"MyVesselInfo",
							"LeaveBalance",
							"FleetUsers",
						],
		}),
		swapCrewMembers: builder.mutation({
			query: ({ vesselId, userId, data }) => ({
				url: `/api/vessels/${vesselId}/users/${userId}/swap`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"Managers",
							"Users",
							"RotationCalendar",
							"RotationContracts",
							"RotationSettings",
							"PositionData",
							"AvailablePositions",
							"MyVesselInfo",
							"LeaveBalance",
							"FleetUsers",
						],
		}),
		changeOnboardStatus: builder.mutation({
			query: ({ vesselId, userId, data }) => ({
				url: `/api/vessels/${vesselId}/users/${userId}/change-onboard`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error ? [] : ["Managers", "Users", , "MyVesselInfo", "FleetUsers"],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetCrewMemberQuery,
	useGetCrewMembersQuery,
	useAddCrewMemberMutation,
	useUpdateCrewMemberMutation,
	useRemoveCrewMemberMutation,
	useImportCrewMemberMutation,
	useSwapCrewMembersMutation,
	useChangeOnboardStatusMutation,
} = crewMembersApiSlice;
