import { apiSlice } from "@/app/api/apiSlice";

export const dayTypeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDayTypes: builder.query({
            query: ({ vesselId }: { vesselId: undefined | number | string }) =>
                `/api/vessels/${vesselId}/days`,
            providesTags: (_result, _error, { vesselId }) => [
                { type: "DayTypes", id: `${vesselId}` }
            ],
        }),
        editDayTypes: builder.mutation({
            query: ({ vesselId, dayTypeId, data }: EditDayTypeArgs) => ({
                url: `/api/vessels/${vesselId}/days/${dayTypeId}`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: (_result, error, { vesselId }) =>
                error
                    ? []
                    : [
                        { type: "DayTypes", id: `${vesselId}` }
                    ],
        }),

        getGlobalDayTypes: builder.query({
            query: () =>
                `/api/day-types`,
            providesTags: (_result, _error) => [
                { type: "DayTypes" }
            ],
        }),
        addGlobalNewDayType: builder.mutation({
            query: ({ data }: AddDayTypeArgs) => ({
                url: `/api/days`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: (_result, error) =>
                error
                    ? []
                    : [
                        { type: "DayTypes" }
                    ],
        }),
        editGlobalDayType: builder.mutation({
            query: ({ data, dayId }: {data: {name: string}, dayId: number | string}) => ({
                url: `/api/days/${dayId}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: (_result, error) =>
                error
                    ? []
                    : [
                        { type: "DayTypes" }
                    ],
        }),
        deleteGlobalDayType: builder.mutation({
            query: ({ dayId }: {dayId: number | string}) => ({
                url: `/api/days/${dayId}`,
                method: "DELETE"
            }),
            invalidatesTags: (_result, error) =>
                error
                    ? []
                    : [
                        { type: "DayTypes" }
                    ],
        }),
    }),
    overrideExisting: true,
});

interface AddDayTypeArgs {
    data: {
        name: string;
        key: string;
    };
}
interface EditDayTypeArgs {
    vesselId: number | string;
    dayTypeId: number | string;
    data: {
        leave_type: number;
    };
}

export const {
    useGetDayTypesQuery,
    useEditDayTypesMutation,

    useGetGlobalDayTypesQuery,
    useAddGlobalNewDayTypeMutation,
    useEditGlobalDayTypeMutation,
    useDeleteGlobalDayTypeMutation
} = dayTypeApiSlice;
