import { format as formatDate, type FormatOptions } from "date-fns";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import { selectPreferredDateFormat } from "@/app/slices/configSlice";
import { parseDateLocal } from "@/utils/timeUtils";
export type FormatType = (date: Date | string, options?: FormatOptions) => string;

export function useDateFormatter() {
	const preferredFormat = useSelector(selectPreferredDateFormat);

	const format = useCallback(
		(date: Date | string, options?: FormatOptions): string => {
			if(!date) return "";

			let parsedDate: Date;

			if (typeof date === "string") {
				parsedDate = parseDateLocal(date);
				if (isNaN(parsedDate.getTime())) {
					console.error(`Invalid date string: "${date}"`);
					return "";
				}
			} else {
				parsedDate = date;
			}

			try {
				return formatDate(parsedDate, preferredFormat, options);
			} catch (error) {
				console.error("Date formatting failed:", error);
				return "";
			}
		},
		[preferredFormat],
	);

	return { format };
}
