import { useState, useLayoutEffect, useMemo } from "react";
import { format as formatFns } from "date-fns";
import { CheckIcon, AlertCircleIcon, RefreshCcwIcon } from "lucide-react";

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
    DialogClose
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import * as Checkbox from '@radix-ui/react-checkbox';
import { isRotationLengthMirrored } from "@/components/calendar/utils";
import { ButtonLoader } from "@/components/ButtonLoader";
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { CalendarPicker } from "@/components/ui/calendar-picker";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

import { useEditChangeDayWithoutDepartmentMutation } from "@/app/api/slices/positionsApiSlice";
import { useCheckIfUserHasParnterAtDayQuery } from "@/app/api/slices/rotationCalendarApiSlice";

import {
	type CalendarPartInterface, 
	type RotationInterface,
	type CrewMemberInterface
} from "@/types/Calendar";
import { parseDateLocal } from "@/utils/timeUtils";

type Props = {
    show: boolean,
    onHide: () => void,
    crewMemberId?: number | undefined,
    crewMemberName?: string | undefined,
    defaultStartDate: string | undefined,
    defaultEndDate: string | undefined,
    rotation: undefined | string,
    positionId: number | undefined,
    calendarDataGroup?: CrewMemberInterface[] | undefined
};

export const AdaptModal = ({
    show, 
    onHide, 
    crewMemberId, 
    crewMemberName, 
    defaultStartDate, 
    defaultEndDate,
    rotation,
    positionId,
    calendarDataGroup
}: Props) => {
    const vessel_id = useCurrentVesselId();
    const { format } = useDateFormatter();
    const { toast } = useToast();

    const [endDate, setEndDate] = useState<Date>();
	const [applyToAllRotations, setApplyToAllRotations] = useState<boolean>(false);
    const [selectedCrewMembers, setSelectedCrewMembers] = useState<number[]>([]);

    const { data: partnerData, isLoading: isLoadingCheckIfUserHasParnterAtDay, isFetching} = useCheckIfUserHasParnterAtDayQuery({ vessel_id, user_id: crewMemberId, date: defaultStartDate }, { skip: !vessel_id || !crewMemberId || !defaultStartDate });
    const [editChangeDay, { isLoading: isLoadingEditChangeDay }] = useEditChangeDayWithoutDepartmentMutation();

    const applyToAllRotationsHandler = (checked: boolean) => {
		setApplyToAllRotations(checked);
	}

    const areDatesSame = (date1: string | Date | undefined, date2: string | Date | undefined) => {
        if(!date1 || !date2) return false;

        return formatFns(parseDateLocal(date1), "y-MM-dd") === formatFns(parseDateLocal(date2), "y-MM-dd");
    }

    const handleShiftRotations = async () => {
        const users = [...selectedCrewMembers];
        if(crewMemberId) {
            users.push(crewMemberId);
        }

		if(!users.length || !defaultStartDate || !endDate) return;

		const reqBody = { 
			vessel_id: vessel_id,
			position_id: positionId,
			data: {
				from: formatFns(parseDateLocal(defaultStartDate), "y-MM-dd"),
				to: formatFns(endDate, "y-MM-dd"),
				users: users,
				applyToAll: isRotationLengthMirrored(rotation) ? applyToAllRotations : false
			}
		}

		await editChangeDay(reqBody)
		.unwrap()
		.then(() => {
			setApplyToAllRotations(false);
            setSelectedCrewMembers([]);
            setEndDate(undefined);

            onHide();

			toast({
				variant: "success",
				title: "Successfully changed rotation change day.",
			});
		})
		.catch((error) => handleServerErrors(error, toast));
	}

    const resetEndDate = (defaultEndDate: string | undefined) => {
        if(defaultEndDate) {
            setEndDate(parseDateLocal(defaultEndDate));
        }
    }

    const selectedCrewMemberHandler = (isChecked: boolean, id: number) => {
        if(isChecked) {
            setSelectedCrewMembers([...selectedCrewMembers, id]);
        } else {
            setSelectedCrewMembers(selectedCrewMembers.filter((crewMemberId) => crewMemberId !== id));
        }
    }

    const onSubmitHandler = () => {
        handleShiftRotations();
    }

    const rotationsDayChanges = useMemo(() => {
		if(!calendarDataGroup) return;

		const rotations = calendarDataGroup.map(({rotations, name, id}: {rotations: RotationInterface[], name: string, id: number}) => {
			return rotations.reduce((acc: Record<string, {id: number; name: string}[]>, {calendar}: {calendar: CalendarPartInterface[]}) => {
				const dates = calendar.reduce((acc: Record<string, {id: number; name: string}[]>, {type, start_date}) => {
					if(["change_day", "travel_day_change_day"].includes(type)) {
                        const date = formatFns(parseDateLocal(start_date), "y-MM-dd");
                            acc[date] = [{name, id}];
					}
					return acc;
				}, {})

                return {...acc, ...dates};
			}, {});
		});
		return rotations;
	}, [calendarDataGroup]);

    const availableCrewMembers = useMemo(() => {
        if(!defaultStartDate || !rotationsDayChanges) return;

        return rotationsDayChanges.map((dates: Record<string, {id: number; name: string}[]>) => {
            return dates[defaultStartDate];
        }).flat().filter(data => data);
    }, [defaultStartDate, rotationsDayChanges]);

    useLayoutEffect(() => {
        if(availableCrewMembers) {
            setSelectedCrewMembers(availableCrewMembers.map(({id}) => id));
        }
    }, [availableCrewMembers]);

    useLayoutEffect(() => {
        if(defaultEndDate && show) {
            setEndDate(parseDateLocal(defaultEndDate));
        }
    }, [defaultEndDate, show]);

	return (
		<Dialog open={show} onOpenChange={onHide} defaultOpen={false}>
            <DialogContent className="sm:max-w-[500px]">
                <DialogHeader>
                    <DialogTitle>Are you absolutely sure?</DialogTitle>
                </DialogHeader>

                <div>
                    <div>
                        <div className="flex flex-wrap items-center text-sm">
                            <span className="me-1">Edit change day from <b className="text-nowrap">{defaultStartDate ? format(defaultStartDate) : "-"}</b> to </span>
                            <div className="flex">
                                <CalendarPicker 
                                    placeholder="Select end date"
                                    disabled={isLoadingCheckIfUserHasParnterAtDay || isLoadingEditChangeDay || isFetching} 
                                    onSelect={setEndDate} 
                                    selected={endDate}
                                    defaultMonth={defaultStartDate ? parseDateLocal(defaultStartDate) : new Date()}
                                />
                                <Button variant="outline" size="icon" className="ms-1 px-2" onClick={() => resetEndDate(defaultEndDate)} disabled={areDatesSame(defaultEndDate, endDate) || isLoadingCheckIfUserHasParnterAtDay || isLoadingEditChangeDay}>
                                    <RefreshCcwIcon width="18" />
                                </Button>
                            </div>
                        </div>
                    </div>

                    <hr className="my-2"/>

                    <div className={cn(`flex flex-col`)} >
                        <label className="text-sm  font-semibold flex items-center">
                            Introduce changes for the selected crew members:
                        </label>

                        <div>
                            {availableCrewMembers?.map(({id, name}) => (
                                <div key={id} className="flex items-center mt-1">
                                    <div className="me-2">
                                        <Checkbox.Root 
                                            className="flex h-7 w-7 items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
                                            onCheckedChange={(isChecked: boolean) => selectedCrewMemberHandler(isChecked, id)}
                                            checked={selectedCrewMembers.includes(id)}
                                            disabled={isLoadingEditChangeDay || availableCrewMembers.length === 1}
                                        >
                                            <Checkbox.Indicator className="CheckboxIndicator">
                                                <CheckIcon className="w-5 h-5 text-rose-600" />
                                            </Checkbox.Indicator>
                                        </Checkbox.Root>
                                    </div>

                                    <label className="text-sm font-normal">
                                        {name}
                                    </label>
                                </div>
                            ))}

                            { crewMemberId && (
                                <>
                                    { isLoadingCheckIfUserHasParnterAtDay && (
                                        <div className="flex justify-center py-2">
                                            <ButtonLoader isLoading={isLoadingCheckIfUserHasParnterAtDay} />
                                        </div>
                                    )}

                                    { !isLoadingCheckIfUserHasParnterAtDay && (
                                        <div className="flex items-center mt-1">
                                            <div className="me-2">
                                                <Checkbox.Root 
                                                    className="flex h-7 w-7 items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
                                                    onCheckedChange={() => {}}
                                                    checked={true}
                                                    disabled={true}
                                                >
                                                    <Checkbox.Indicator className="CheckboxIndicator">
                                                        <CheckIcon className="w-5 h-5 text-rose-600" />
                                                    </Checkbox.Indicator>
                                                </Checkbox.Root>
                                            </div>

                                            <label className="text-sm font-normal text-muted-foreground">
                                                {crewMemberName}
                                            </label>
                                        </div>
                                    )}

                                    { (!isLoadingCheckIfUserHasParnterAtDay && partnerData?.data?.partner && partnerData?.data?.isSameDay) && (
                                        <div className="flex items-center mt-1">
                                            <div className="me-2">
                                                <Checkbox.Root 
                                                    className="flex h-7 w-7 items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
                                                    onCheckedChange={(isChecked: boolean) => selectedCrewMemberHandler(isChecked, partnerData?.data?.partner?.id)}
                                                    checked={selectedCrewMembers.includes(partnerData?.data?.partner?.id)}
                                                    disabled={isLoadingEditChangeDay}
                                                >
                                                    <Checkbox.Indicator className="CheckboxIndicator">
                                                        <CheckIcon className="w-5 h-5 text-rose-600" />
                                                    </Checkbox.Indicator>
                                                </Checkbox.Root>
                                            </div>

                                            <label className="text-sm font-normal">
                                                { partnerData?.data?.partner?.name }
                                            </label>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <hr className="mt-2"/>
                    </div>

                    <div className={cn(`mt-2 flex items-center`, isRotationLengthMirrored(rotation) ? "" : "hidden")} >
                        <div className="me-2">
                            <Checkbox.Root 
                                className="flex h-7 w-7 items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
                                onCheckedChange={applyToAllRotationsHandler}
                                checked={applyToAllRotations}
                                disabled={isLoadingEditChangeDay || isLoadingCheckIfUserHasParnterAtDay}
                            >
                                <Checkbox.Indicator className="CheckboxIndicator">
                                    <CheckIcon className="w-5 h-5 text-rose-600" />
                                </Checkbox.Indicator>
                            </Checkbox.Root>
                        </div>

                        <label className="text-sm text-rose-600 font-semibold flex items-center">
                            <AlertCircleIcon className="me-1"/>
                            Adapt calendar to the manual change
                        </label>
                    </div>
                </div>
                
                
                <div className="flex justify-between">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button onClick={onSubmitHandler} disabled={isLoadingEditChangeDay || isLoadingCheckIfUserHasParnterAtDay}>
                        <ButtonLoader isLoading={isLoadingEditChangeDay || isLoadingCheckIfUserHasParnterAtDay} />
                        Continue
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
	);
};
