import { type DayType } from "@/types/DayType";
import { DayTypeDialog } from "@/components/dialogs/DayTypeDialog";

type Props = {
	dayType: DayType;
};

export const GridItem = ({ dayType }: Props) => {
	const {
		name,
		updated_at,
		leave_type,
	} = dayType;

	const getLeaveBalanceStatus = (leave_balance_on_off: 0 | 1 | 2) => {
		switch(leave_balance_on_off) {
			case 0:
				return "Leave Neutral";
			case 1:
				return "Leave Accrue";
			case 2:
				return "Use Leave";
			default:
				return "-";
		}
	}

	return (
		<div>
			<h3 className="text-xl font-semibold">{`${name}`}</h3>
			<div className="mb-2 border-t border-black"></div>

			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Updated at</span>
				<span>{updated_at}</span>
			</div>

			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Leave Balance Calculation</span>
				<span>{getLeaveBalanceStatus(leave_type)}</span>
			</div>

			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Actions</span>
				<div className="flex gap-2">
					<DayTypeDialog dayTypeData={dayType} />
				</div>
			</div>
		</div>
	);
};
