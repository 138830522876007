import { useChangeOnboardStatusMutation } from "@/app/api/slices/crewMembersApiSlice";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useToast } from "@/components/ui/use-toast";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { Loader } from "lucide-react";
import { useState } from "react";

type Props = {
	userId: number;
	isSignedOn: boolean;
	isOnboard: boolean;
	label?: string;
};

export const UserTemporaryOnboardSwitch = ({ userId, isSignedOn, isOnboard, label }: Props) => {
	const { toast } = useToast();
	const vesselId = useCurrentVesselId();

	const [changeOnboardStatusMutation, { isLoading }] = useChangeOnboardStatusMutation();

	const [switchValue, setSwitchValue] = useState(isOnboard);
	const handleStatusChange = async (newValue: boolean) => {
		setSwitchValue(newValue);
		await changeOnboardStatusMutation({ vesselId, userId, data: { value: newValue } })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "User onboard status changed.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	// if (!isSignedOn) {
	// 	return null;
	// }

	return (
		<div className="flex items-center space-x-2">
			{label && <Label>{label}</Label>}
			<Switch checked={switchValue} onCheckedChange={handleStatusChange} disabled={!isSignedOn} />
			{isLoading && <Loader className="animate-spin duration-2000" />}
		</div>
	);
};
