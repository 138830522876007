import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useCancelPaymentMutation } from "@/app/api/slices/manager/paymentsApiSlice";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const PaymentCancelPage = () => {
	const { toast } = useToast();

	const vesselId = useCurrentVesselId();

	const [cancelPaymentMutation] = useCancelPaymentMutation();

	useEffect(() => {
		cancelPaymentMutation({
			vesselId,
		})
			.unwrap()
			.then((_payload) => {})
			.catch((error) => handleServerErrors(error, toast));
	}, []);

	return (
		<div className="flex h-full items-center justify-center bg-ground">
			<div className="mb-6 w-full max-w-(--breakpoint-lg) p-4 text-center md:p-8">
				<div className="mx-auto mt-6 rounded-lg bg-white p-6 text-center">
					<h1 className="text-xl font-bold text-gray-800 sm:text-2xl md:text-3xl lg:text-4xl">
						Your payment could not be processed.
					</h1>
					<p className="mb-1 mt-3 text-base text-gray-600 md:text-lg">
						Unfortunately, the payment process was canceled or encountered an issue.
					</p>
					<p className="mb-4 text-base text-gray-600 md:text-lg">
						This might be due to various reasons such as insufficient funds, declined card, or
						technical difficulties.
					</p>
					<Button asChild>
						<Link to="/">Go back to dashboard</Link>
					</Button>
				</div>
			</div>
		</div>
	);
};
