import { Pen } from "lucide-react";
import { useState } from "react";

import { 
	useEditDayTypesMutation
} from "@/app/api/slices/dayTypeApiSlice";
import { DayTypeUpdateForm } from "@/components/forms/DayTypeUpdateForm";
import { type FormType } from "@/components/forms/DayTypeUpdateForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { type DayType } from "@/types/DayType";

type Props = {
	dayTypeData: DayType;
};

export const DayTypeDialog = ({ dayTypeData }: Props) => {
	const vesselId = useCurrentVesselId();

	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [editDayTypeMutation, { isLoading }] = useEditDayTypesMutation();

	const handleUpdateCrewMember = async (data: FormType) => {
		if(!vesselId) return;

		const requestBody = {
			leave_type: Number(data.leave_type),
		}

		await editDayTypeMutation({ vesselId, data: requestBody, dayTypeId: dayTypeData.id })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully updated day type.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button
					className="edit-crew-member-button"
					variant="outline"
					size="icon"
					style={{ background: "#2055A3" }}
				>
					<Pen color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Edit Day Type</DialogTitle>
				</DialogHeader>

				<DayTypeUpdateForm
					onSubmit={handleUpdateCrewMember}
					isLoading={isLoading}
					defaultValues={{
						leave_type: String(dayTypeData.leave_type),
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};
